import { formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useAppInfo } from "@src/appV2/lib/utils/capacitor/useAppInfo";
import { useGetShiftFeedback } from "@src/appV2/ShiftFeedback/useGetShiftFeedback";
import { useRevampWorkplaceSigningFlow } from "@src/appV2/Shifts/Shift/useRevampWorkplaceSigningFlow";
import { isSolveUnpaidBreaksFeatureEnabled } from "@src/appV2/Shifts/utils/isSolveUnpaidBreaksFeatureEnabled";
import { type ReactElement } from "react";

import { type ShiftStateDependencies, type ShiftStateMetadata } from "../types";

interface Props {
  shiftStateMetadata: ShiftStateMetadata;
  shiftStateDependencies: ShiftStateDependencies;
}

export function ClockOutContent(props: Props): ReactElement {
  const {
    shiftStateMetadata: {
      isShiftOver,
      isDigitalSignatureEnabled,
      displayTimeSheetSummary,
      solveUnpaidBreaksRolloutStageWithTargetingConfig,
      isTimeSheetEditDisabled,
    },
    shiftStateDependencies,
  } = props;
  const { agentId, _id: shiftId, facility, end: shiftEnd } = shiftStateDependencies;

  const { appInfo } = useAppInfo();
  const { qrCodeSignatureBeforeClockOutIsEnabled } = useRevampWorkplaceSigningFlow(facility.id);
  const { data: shiftFeedback } = useGetShiftFeedback(
    { workerId: agentId ?? "", shiftId },
    {
      enabled: qrCodeSignatureBeforeClockOutIsEnabled && isDefined(agentId) && isDefined(shiftId),
    }
  );
  const hasShiftFeedback = isDefined(shiftFeedback) && shiftFeedback.length > 0;

  const formattedClockOutTime = formatTime(shiftEnd, {
    timeZone: facility.tmz,
  });
  const isSolveUnpaidBreaksEnabled =
    isDigitalSignatureEnabled &&
    displayTimeSheetSummary &&
    isSolveUnpaidBreaksFeatureEnabled({
      solveUnpaidBreaksRolloutStageWithTargetingConfig,
      appInfo,
    });
  const showAddBreakText = !isTimeSheetEditDisabled && !isShiftOver && isSolveUnpaidBreaksEnabled;

  if (qrCodeSignatureBeforeClockOutIsEnabled) {
    return hasShiftFeedback ? (
      <Text>Last step: clock out to get paid!</Text>
    ) : (
      <Text>
        You&apos;ve clocked in! When you are ready to clock out, get a signature from the workplace
        employee you worked with. You can add your break times and modify your timesheet before they
        sign.
      </Text>
    );
  }

  return (
    <>
      <Text>
        Please try to clock out by <b>{formattedClockOutTime}</b>.{" "}
        {showAddBreakText &&
          "You will be able to add your break times after clock-out, while submitting your timesheet."}
      </Text>
      {isShiftOver ? (
        <>
          <Text>It seems like you&apos;re running late.</Text>
          {!isTimeSheetEditDisabled && displayTimeSheetSummary && (
            <Text>Don&apos;t worry - you can edit your timesheet at the end.</Text>
          )}
        </>
      ) : null}
    </>
  );
}
